export default {
    async getItem(seoItemId) {
        try {
            const seo_items_response = await API.apiClient.get(
                "/seo_item/" + seoItemId
            );

            if (
                seo_items_response &&
                seo_items_response instanceof Object &&
                seo_items_response.data
            ) {
                return seo_items_response.data;
            } else {
                return null;
            }
        } catch (error) {
            errorHandler(error);
        }
    },
};
