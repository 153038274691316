<template>
    <div class="box">
        <div class="box-header with-border">
            <h3>
                <previous-route-block/>

                {{ page_title }}
            </h3>
            <transition name="fade">
                <h3 v-if="seo_item && onProgress === false" class="mb-0">
                    <small class="pull-left">
                        {{ seo_item.item_type.toUpperCase() }} #{{ seo_item.item_id }}
                    </small>
                    <small v-if="seo_item.updated_date" class="font-small pull-right">
                        Обновлено {{ seo_item.updated_date }}
                    </small>
                </h3>
            </transition>
        </div>

        <div class="box-body">
            <form @keydown="form.onKeydown($event)" @submit.prevent="editSEOItem"
                  v-if="seo_item && onProgress === false">

                <vue-tabs class="panel">
                    <v-tab :key="index"
                           :title="locale.toUpperCase()"
                           class="nav-tab-content"
                           v-for="(locale, index) in locales">
                        <div class="form-group">
                            <div class="row">
                                <label class="col-md-2 control-label" for="title">
                                    SEO-заголовок
                                    <small>({{ locale.toUpperCase() }})</small>
                                </label>
                                <div class="col-md-10"
                                     v-bind:class="{ 'has-error': form.errors.has(locale + '.title') }">
                                    <input class="form-control"
                                           id="title"
                                           type="text"
                                           placeholder="SEO Title"
                                           title="SEO Title"
                                           v-model="form[locale].title">
                                    <HasError :field="locale + '.title'" :form="form"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <label class="col-md-2 control-label" for="h1_title">
                                    Заголовок страницы
                                    <small>({{ locale.toUpperCase() }})</small>
                                </label>
                                <div class="col-md-10"
                                     v-bind:class="{ 'has-error': form.errors.has(locale + '.h1_title') }">
                                    <input class="form-control"
                                           id="h1_title"
                                           type="text"
                                           placeholder="Заголовок страницы (H1)"
                                           title="Заголовок страницы (H1)"
                                           v-model="form[locale].h1_title">
                                    <HasError :field="locale + '.h1_title'" :form="form"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <label class="col-md-2 control-label" for="description">
                                    SEO-описание
                                    <small>({{ locale.toUpperCase() }})</small>
                                </label>
                                <div class="col-md-10"
                                     v-bind:class="{ 'has-error': form.errors.has(locale + '.description') }">
                                            <textarea class="form-control"
                                                      id="description"
                                                      placeholder="SEO Description"
                                                      title="SEO Description"
                                                      rows="4"
                                                      v-model="form[locale].description">
                                            </textarea>
                                    <HasError :field="locale + '.description'" :form="form"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <label class="col-md-2 control-label" for="keywords">
                                    Ключевые слова
                                    <small>({{ locale.toUpperCase() }})</small>
                                </label>
                                <div class="col-md-10"
                                     v-bind:class="{ 'has-error': form.errors.has(locale + '.keywords') }">
                                            <textarea class="form-control"
                                                      id="keywords"
                                                      placeholder="SEO Keywords"
                                                      title="SEO Keywords"
                                                      rows="3"
                                                      v-model="form[locale].keywords">
                                            </textarea>
                                    <HasError :field="locale + '.keywords'" :form="form"/>
                                </div>
                            </div>
                        </div>
                    </v-tab>
                </vue-tabs>

                <br/>

                <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

                <Button :disabled="!validateData || form.busy"
                        :form="form"
                        :loading="form.busy"
                        class="btn btn-lg btn-warning pull-right"
                        style="min-width: 100px;">
                    <i class="fa fa-edit"></i>
                </Button>
            </form>
        </div>
    </div>
</template>

<script>
    import Form from 'vform';
    import {VTab, VueTabs} from 'vue-nav-tabs';
    import 'vue-nav-tabs/themes/vue-tabs.css';
    import LocaleService from "../../services/LocaleService";
    import SEOItemService from "../../services/SEOItemService";

    Form.axios = API.apiClient;

    export default {
        name: 'edit-seo-item',

        components: {
            Button, HasError, AlertError, AlertErrors,
            VueTabs,
            VTab,
        },

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        props: [
            'seoItemId',
        ],

        data: () => ({
            page_title: 'SEO-данные',

            form: new Form(),

            seo_item: null,

            locales: [],

            onProgress: true,
        }),

        async created() {
            try {
                this.locales = await LocaleService.getItems();
                this.seo_item = await SEOItemService.getItem(parseInt(this.seoItemId));

                this.fillForm();
            } catch (error) {
                errorHandler(error);
            }

            this.onProgress = false;
        },

        computed: {
            validateData: function () {
                return this.seoItemId && parseInt(this.seoItemId) === this.seo_item.id && this.locales &&
                    this.locales.length > 0;
            },
        },

        methods: {
            fillForm() {
                try {
                    this.seo_item.translations.forEach(translate_item => {
                        this.form[translate_item.locale] = {
                            title: translate_item.title,
                            h1_title: translate_item.h1_title,
                            description: translate_item.description,
                            keywords: translate_item.keywords,
                        }
                    });

                } catch (error) {
                    errorHandler(error);
                }
            },

            async editSEOItem() {
                this.onProgress = true;

                await this.form.put('/seo_items/edit/' + this.seoItemId).then(() => {
                    showSuccess();
                }).catch((error) => {
                    errorHandler(error);
                });

                this.onProgress = false;
            },
        }
    }
</script>

<style scoped>

</style>
